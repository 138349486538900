import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { chainStatus, nodeTypes, routes, socketEndpoints } from '../../constants';
import {formatUptime, getChainName, getCoinImage, getStatusColorClass} from '../../util';
import NodeTableHeader from "./node-table-header";
import {
  NodeDisplay, NodeDisplayColumn, NodeDisplayColumnHeader, NodeDisplayColumnLink, NodeDisplayColumnText,
  NodeDisplayImageColumn,
  NodeDisplayImageColumnImage,
  NodeDisplayImageColumnText
} from "./node-display";
import { Map } from 'immutable';
import $ from 'jquery';
import { Link } from "react-router-dom";
import ChainModal from './chain-modal';
import NLNode from '../../types/NLNode';
import swal from 'sweetalert';

const UpgradeButton = ({authKey, socket, node, availableUpgrade}) => {

  const [ disabled, setDisabled ] = useState(false);

  const styles = {
    upgradeButton: {
      display: disabled ? 'none' : 'block',
      marginTop: '.5em',
      marginBottom: '.5em',
      marginLeft: '.5em',
    },
  }

  const onUpgradeNodeClick = async e => {
    e.preventDefault();
    if(disabled)
      return;
    const confirmed = await swal({
      title: 'Upgrade Confirmation',
      text: `You are about to upgrade ${node.id} to version ${availableUpgrade.version}. Do you want to continue?`,
      buttons: [
        'Cancel',
        'Upgrade Node',
      ]
    });
    if(!confirmed)
      return;
    setDisabled(true);
    socket.emit(socketEndpoints.UPGRADE_NODE, authKey, node.id, availableUpgrade, (err, success) => {
      if(err) {
        console.error(err);
        swal({
          icon: 'error',
          title: 'Upgrade Error',
          text: err.message,
        });
        setDisabled(false);
      } else if(success) {
        setTimeout(() => {
          swal({
            icon: 'success',
            title: 'Success',
            text: `${node.id} successfully upgraded to version ${availableUpgrade.version}`,
          });
          setDisabled(false);
        }, 4000);
      } else { // not successful
        swal({
          icon: 'warning',
          title: 'Upgrade Failed',
          text: `${node.id} was not able to upgrade to version ${availableUpgrade.version}. Check your logs for more details.`,
        });
        setDisabled(false);
      }
    });
  };

  return (
    <a className={`btn btn-outline-primary white-box-shadow-button ml-2 ${disabled ? 'disabled' : ''}`} style={styles.upgradeButton} href="#" title={`Update ${node.id} to v${availableUpgrade.version}`} disabled={disabled} onClick={onUpgradeNodeClick}>UPDATE</a>
  );
};

const ChainsTable = ({ authKey, noHeader = false, chains, chainsMeta, nodeUpgrades, history, socket }) => {

  const unknownStr = chainStatus.UNKNOWN;

  const onAddChainClick = e => {
    e.preventDefault();
    $('#js-newChainModal').modal('show');
  };

  return (
    <div className={'container-fluid'}>
      {noHeader ?
        null
        :
        <div className={'row'}>
          <div className={'col'}>
            <NodeTableHeader text={'CHAINS'} onAddClick={onAddChainClick} />
          </div>
        </div>
      }
      <div className={'row'}>
        <div className={'col'}>

          {chains
            .sort((a, b) => getChainName(a.ticker).localeCompare(getChainName(b.ticker)))
            .map(n => {
              const meta = chainsMeta.get(n.id) || {};
              const version = (n.remote || meta.version) ? meta.version : n.version;

              const availableUpgrade = !n.remote ? nodeUpgrades[n.id] : null;

              return (
                <div key={n.id} className={'row'}>
                  <div className={'col'}>
                    <NodeDisplay>

                      <NodeDisplayImageColumn>
                        <NodeDisplayImageColumnImage src={getCoinImage(n.ticker)} alt={`${n.ticker.toUpperCase()} logo`} />
                        <NodeDisplayImageColumnText>{n.network.toUpperCase()}</NodeDisplayImageColumnText>
                      </NodeDisplayImageColumn>

                      <NodeDisplayColumn>
                        <NodeDisplayColumnHeader><span style={{color: !n.remote ? '#727cf5': '#8391a2'}}>{n.remote ? 'REMOTE' : 'LOCAL'}</span></NodeDisplayColumnHeader>
                        <NodeDisplayColumnText>{n.id.toUpperCase()}</NodeDisplayColumnText>
                      </NodeDisplayColumn>

                      <NodeDisplayColumn>
                        <NodeDisplayColumnHeader>{'VERSION'}</NodeDisplayColumnHeader>
                        <NodeDisplayColumnText>{version || unknownStr}</NodeDisplayColumnText>
                      </NodeDisplayColumn>

                      <NodeDisplayColumn>
                        <NodeDisplayColumnHeader>{'MEMORY'}</NodeDisplayColumnHeader>
                        <NodeDisplayColumnText>{meta.memUsed ? `${meta.memUsed} / ${meta.memAllocated}` : n.remote ? 'N/A' : '0 / 0'}</NodeDisplayColumnText>
                      </NodeDisplayColumn>

                      <NodeDisplayColumn>
                        <NodeDisplayColumnHeader>{'UPTIME'}</NodeDisplayColumnHeader>
                        <NodeDisplayColumnText>{n.remote || !meta.startTime ? 'N/A' :  formatUptime(meta.startTime)}</NodeDisplayColumnText>
                      </NodeDisplayColumn>

                      <NodeDisplayColumn>
                        <NodeDisplayColumnHeader>{'STATUS'}</NodeDisplayColumnHeader>
                        <NodeDisplayColumnText className={getStatusColorClass(meta.status)}>{meta.status || unknownStr}</NodeDisplayColumnText>
                      </NodeDisplayColumn>

                      <NodeDisplayColumn>
                        <NodeDisplayColumnHeader>{'BLOCK'}</NodeDisplayColumnHeader>
                        <NodeDisplayColumnText>{meta.blockHeight || unknownStr}</NodeDisplayColumnText>
                      </NodeDisplayColumn>

                      <NodeDisplayColumn>
                        <div className={'d-flex flex-row justify-content-start flex-wrap'}>
                          <div className={'d-flex flex-column justify-content-center'}>
                            <NodeDisplayColumnLink to={routes.CHAIN + `/${n.id}`} icon={'mdi-chevron-right-circle'} title={'View chain details'} />
                          </div>
                          {availableUpgrade ? <UpgradeButton authKey={authKey} socket={socket} node={n} availableUpgrade={availableUpgrade} /> : ''}
                        </div>
                      </NodeDisplayColumn>

                    </NodeDisplay>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>

      <ChainModal history={history} socket={socket} />

    </div>
  )
};
ChainsTable.propTypes = {
  noHeader: PropTypes.bool,
  authKey: PropTypes.string,
  chains: PropTypes.arrayOf(PropTypes.instanceOf(NLNode)),
  chainsMeta: PropTypes.instanceOf(Map),
  history: PropTypes.object,
  socket: PropTypes.object,
  nodeUpgrades: PropTypes.object,
};

export default connect(
  ({ appState }) => ({
    authKey: appState.authKey,
    chainsMeta: appState.chainsMeta,
    nodeUpgrades: appState.nodeUpgrades,
  })
)(ChainsTable);
