import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {routes} from "../../constants";
import {getCoinImage} from "../../util";

export const NodeDisplayColumnLink = ({ className = '', icon, title = '', to, style = {} }) => {
  return (
    <div><Link to={to} style={{fontSize: 28, ...style}} className={`white-link ${className}`} title={title}><i className={`mdi ${icon}`} /></Link></div>
  );
};
NodeDisplayColumnLink.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  to: PropTypes.string,
  style: PropTypes.object,
};

export const NodeDisplayImageColumnImage = ({ src, alt, className = '', style = {} }) => {
  return (
    <img className={className} style={{width: 47, height: 47, marginBottom: 8, ...style}} src={src} alt={alt} />
  );
};
NodeDisplayImageColumnImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

export const NodeDisplayImageColumnText = ({ children, className = '', style = {} }) => {
  return (
    <div className={className} style={{fontSize: 15, lineHeight: '15px', ...style}}>{children}</div>
  );
};
NodeDisplayImageColumnText.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
};

export const NodeDisplayColumnText = ({ children, className = '', style = {} }) => {
  return (
    <div className={`font-weight-bold ${className}`} style={style}>{children}</div>
  );
};
NodeDisplayColumnText.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
};

export const NodeDisplayColumnHeader = ({ children, className = '', style = {} }) => {
  return (
    <div className={`text-primary font-weight-bold ${className}`} style={{fontSize: 14, ...style}}>{children}</div>
  );
};
NodeDisplayColumnHeader.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
};

export const NodeDisplayImageColumn = ({ children, className = '', style = {} }) => {
  return (
    <div style={{flexBasis: '1px', ...style}} className={'d-flex flex-grow-1 flex-column justify-content-center align-items-center'}>
      {children}
    </div>
  );
};
NodeDisplayImageColumn.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
};

export const NodeDisplayColumn = ({ children, className = '', style = {} }) => {
  return (
    <div style={{flexBasis: '1px', ...style}} className={`d-flex flex-grow-1 flex-column justify-content-center align-items-start ${className}`}>
      {children}
    </div>
  );
};
NodeDisplayColumn.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
};

export const NodeDisplay = ({ children, className = '', style = {} }) => {
  return (
    <div className={`card d-flex flex-row justify-content-start no-wrap ${className}`} style={{overflowX: 'hidden', padding: 20, fontSize: 15, ...style}}>
      {children}
    </div>
  )
};
NodeDisplay.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
};
