import swal from 'sweetalert';
import { chains, chainStatus, Role, socketEndpoints } from '../constants';
import genericImage from '../images/coins/128/generic.png';
import poktImage from '../images/coins/128/pokt.png';
import bchImage from '../images/coins/128/bch.png';
import btcImage from '../images/coins/128/btc.png';
import dashImage from '../images/coins/128/dash.png';
import dgbImage from '../images/coins/128/dgb.png';
import ethImage from '../images/coins/128/eth.png';
import ltcImage from '../images/coins/128/ltc.png';
import lbcImage from '../images/coins/128/lbc.png';
import avaxImage from '../images/coins/128/avax.png';
import bsvImage from '../images/coins/128/bsv.png';
import xdaiImage from '../images/coins/128/xdai.png';
import bscImage from '../images/coins/128/bsc.png';
import fuseImage from '../images/coins/128/fuse.png';
import oneImage from '../images/coins/128/one.png';
import maticImage from '../images/coins/128/matic.png';
import iotexImage from '../images/coins/128/iotex.png';
import oecImage from '../images/coins/128/oec.png';
import moment from 'moment';

export const handleError = err => {
  console.error(err);
  if (err.message === 'Unauthorized')
    swal('Oops!', err.message, 'error')
      .then(() => window.location.reload());
  else
    swal('Oops!', err.message, 'error');
};

const chainNames = {
  [chains.POKT]: 'Pocket',
  [chains.ETH]: 'Ethereum',
  [chains.BTC]: 'Bitcoin',
  [chains.ICX]: 'ICON',
  [chains.BCH]: 'Bitcoin Cash',
  [chains.BSV]: 'Bitcoin SV',
  [chains.DASH]: 'Dash',
  [chains.DGB]: 'DigiByte',
  [chains.LBC]: 'LBRY Credits',
  [chains.LTC]: 'Litecoin',
  [chains.SYS]: 'Syscoin',
  [chains.AVAX]: 'Avalanche',
  [chains.XDAI]: 'Gnosis Chain',
  [chains.BSC]: 'Binance Smart Chain',
  [chains.FUSE]: 'Fuse',
  [chains.ONE]: 'Harmony One',
  [chains.OEC]: 'OKExChain',
  [chains.IOTEX]: 'IoTeX',
  [chains.MATIC]: 'Polygon',
};

export const getChainName = ticker => chainNames[ticker];

export const getChainRoleName = role => {
  switch(role) {
    case(Role.NODE):
      return 'Full Node';
    case(Role.VALIDATOR):
      return 'Validator';
    default: {
      return '';
    }
  }
}

export const getCoinImage = ticker => {
  switch(ticker) {
    case 'pokt':
      return poktImage;
    case chains.BCH:
      return bchImage;
    case chains.BTC:
      return btcImage;
    case chains.DASH:
      return dashImage;
    case chains.DGB:
      return dgbImage;
    case chains.ETH:
      return ethImage;
    case chains.LTC:
      return ltcImage;
    case chains.LBC:
      return lbcImage;
    case chains.AVAX:
      return avaxImage;
    case chains.BSV:
      return bsvImage;
    case chains.XDAI:
      return xdaiImage;
    case chains.BSC:
      return bscImage;
    case chains.FUSE:
      return fuseImage;
    case chains.ONE:
      return oneImage;
    case chains.OEC:
      return oecImage;
    case chains.IOTEX:
      return iotexImage;
    case chains.MATIC:
      return maticImage;
    default:
      return genericImage;
  }
};

export const getStatusColorClass = status => {
  switch(status) {
    case chainStatus.RUNNING:
      return 'text-success';
    case chainStatus.STOPPED:
      return 'text-danger';
    case chainStatus.SYNCING:
      return '';
    case chainStatus.JAILED:
      return 'text-danger';
    default:
      return '';
  }
};

export const formatUptime = startTime => {
  const now = new Date().getTime();
  const startNum = moment(startTime).toDate().getTime();
  const diff = now - startNum;
  if(diff < 60000) {
    const num = moment().diff(moment(startTime), 'seconds');
    return `${num} ${num === 1 ? 'second' : 'seconds'}`;
  } else if(diff < 3600000) {
    const num = moment().diff(moment(startTime), 'minutes');
    return `${num} ${num === 1 ? 'minute' : 'minutes'}`;
  } else if(diff < 86400000) {
    const num = moment().diff(moment(startTime), 'hours');
    return `${num} ${num === 1 ? 'hour' : 'hours'}`;
  } else if(diff >= 86400000) {
    const num = moment().diff(moment(startTime), 'days');
    return `${num} ${num === 1 ? 'day' : 'days'}`;
  } else {
    return '';
  }
};

export const titleCase = str => {
  let newStr = '';
  for(let i = 0; i < str.length; i++) {
    const char = str[i];
    if(i === 0 || (i > 0 && /\s/.test(str[i - 1]))) {
      newStr += char.toUpperCase();
    } else {
      newStr += char;
    }
  }
  return newStr;
};

export const timeout = (length = 0) => new Promise(resolve => {
  setTimeout(resolve, length);
});

export const separateCamelCase = (str = '') => {
  const splitStr = str.split('');
  let newStr = '';
  for(let i = 0; i < splitStr.length; i++) {
    const char = splitStr[i];
    const upper = char.toUpperCase();
    if(i === 0) {
      newStr += upper;
    } else if(upper === char) {
      newStr += ` ${upper}`;
    } else {
      newStr += char;
    }
  }
  return newStr;
};

/**
 * @param socket
 * @param authKey
 * @returns {Promise<string>}
 */
export const generatePassword = (socket, authKey) => {
  return new Promise((resolve, reject) => {
    socket.emit(socketEndpoints.GENERATE_PASSWORD, authKey, (err, newPassword) => {
      if(err) {
        reject(err);
      } else {
        resolve(newPassword);
      }
    });
  });
}
