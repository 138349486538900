import { actions } from '../constants';

export const setWindowSize = (width, height) => ({
  type: actions.SET_WINDOW_SIZE,
  payload: {
    width,
    height,
  },
});

export const setReady = ready => ({
  type: actions.SET_READY,
  payload: {
    ready
  },
});

export const setAuthKey = authKey => ({
  type: actions.SET_AUTH_KEY,
  payload: {
    authKey
  },
});

export const setPublicIP = publicIP => ({
  type: actions.SET_PUBLIC_IP,
  payload: {
    publicIP
  },
});

export const setConfig = config => ({
  type: actions.SET_CONFIG,
  payload: {
    config
  },
});

export const setDomainVerified = verified => ({
  type: actions.SET_DOMAIN_VERIFIED,
  payload: {
    verified
  },
});

export const setPortVerified = verified => ({
  type: actions.SET_PORT_VERIFIED,
  payload: {
    verified
  },
});

export const setPlatform = platform => ({
  type: actions.SET_PLATFORM,
  payload: {
    platform
  },
});

export const setLastDomainCheckTime = lastDomainCheckTime => ({
  type: actions.SET_LAST_DOMAIN_CHECK_TIME,
  payload: {
    lastDomainCheckTime
  }
});

export const setLastPortCheckTime = lastPortCheckTime => ({
  type: actions.SET_LAST_PORT_CHECK_TIME,
  payload: {
    lastPortCheckTime
  }
});

export const setTLSVerified = verified => ({
  type: actions.SET_TLS_VERIFIED,
  payload: {
    verified
  }
});

export const setLastTLSCheckTime = lastTLSCheckTime => ({
  type: actions.SET_LAST_TLS_CHECK_TIME,
  payload: {
    lastTLSCheckTime
  }
});

export const setChains = chains => ({
  type: actions.SET_CHAINS,
  payload: {
    chains
  }
});

export const setNodeOutput = nodeOutput => ({
  type: actions.SET_NODE_OUTPUT,
  payload: {
    nodeOutput
  }
});

export const setChainMeta = (_id, data) => ({
  type: actions.SET_CHAIN_META,
  payload: {
    _id,
    data,
  }
});

export const setSystemInfo = systemInfo => ({
  type: actions.SET_SYSTEM_INFO,
  payload: {
    systemInfo
  }
});

export const setNodePilotVersion = version => ({
  type: actions.SET_NODE_PILOT_VERSION,
  payload: {
    version
  }
});

export const setPocketSimulatedRelays = simulatedRelays => ({
  type: actions.SET_POCKET_SIMULATED_RELAYS,
  payload: {
    simulatedRelays,
  }
});

export const setNodeUpgrades = nodeUpgrades => ({
  type: actions.SET_NODE_UPGRADES,
  payload: {
    nodeUpgrades,
  }
});

export const setIsRootUser = isRootUser => ({
  type: actions.SET_IS_ROOT_USER,
  payload: {
    isRootUser,
  }
});
