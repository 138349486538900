import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { PocketChainDataItem } from '../../types/pocket-chain-data-item';

const ChainDataItem = ({ chain: c, onDelete = () => {} }) => {
  const [ showDelete, setShowDelete ] = useState(false);
  const namePatt = /https?:\/\/(.+)/;
  const matches = c.url.match(namePatt);
  const onMouseOver = e => {
    e.preventDefault();
    if(!showDelete)
      setShowDelete(true);
  };
  const onMouseOut = e => {
    e.preventDefault();
    setShowDelete(false);
  };
  const onDeleteClick = e => {
    e.preventDefault();
    onDelete(c);
  };
  return (
    <tr onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
      <td>{c.id}</td>
      <td className={'text-nowrap'} title={matches[1]}>{matches[1]}</td>
      <td><a style={{display: showDelete ? 'inline' : 'none'}} href={'#'} className={'text-danger'} onClick={onDeleteClick}>Remove</a></td>
    </tr>
  );
};

const PocketChainData = ({ chainData, onAddChain = () => {}, onDeleteChain = () => {} }) => {

  const styles = {
    label: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  };

  const onAddChainClick = e => {
    e.preventDefault();
    onAddChain();
  };

  return (
    <div className={'row'}>
      <div className={'col-lg-8 col-sm-12 form-group'}>
        <label style={styles.label}><span className={'mr-1'}>Connected Chains:</span><a href={'https://docs.pokt.network/home/resources/references/supported-blockchains'} target={'_blank'}><em>(Pocket relay reference)</em></a><span className={'flex-grow-1'} /><a href={'#'} className={'btn btn-primary btn-sm'} onClick={onAddChainClick}>Add Chain</a></label>
        <div style={{overflowX: 'hidden'}}>
          <table className={'table table-sm'}>
            <thead>
            <tr>
              <th>{'ID'}</th>
              <th>{'Name'}</th>
              <th style={{width: 80}} />
            </tr>
            </thead>
            <tbody>
            {chainData.map(c => <ChainDataItem key={c.id + c.url} chain={c} onDelete={onDeleteChain} />)}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
PocketChainData.propTypes = {
  chainData: PropTypes.arrayOf(PropTypes.instanceOf(PocketChainDataItem)),
  onAddChain: PropTypes.func,
  onDeleteChain: PropTypes.func,
};

export default PocketChainData;
