import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  nodeTypes,
  chains as chainTickers,
  socketEndpoints,
  routes,
  chainDefaults,
  networkTypes,
  transportProtocols,
  Role,
} from '../../constants';
import { generatePassword, getChainName, handleError, separateCamelCase, timeout } from '../../util';
import { Link } from 'react-router-dom';
import { Map, Set } from 'immutable';
import ChainDirSelect from "../shared/chain-dir-select";
import swal from 'sweetalert';
import NLNode from '../../types/NLNode';
import * as math from 'mathjs';
import isString from 'lodash/isString';
import { PocketChainDataItem } from '../../types/pocket-chain-data-item';
import PocketChainData from '../shared/pocket-chain-data';
import AddPocketRelayChain from '../shared/add-pocket-relay-chain';
import $ from 'jquery';
import OutputModal from '../shared/output-modal';

const generateUsername = ticker => {
  return `${ticker}-user`;
};

const AddNewChain = ({ location, authKey, chains, history, readOnly = false, selectedChain, socket, nodeOutput }) => {

  let defaultTicker;
  if(location) {
    const queryParams = new URLSearchParams(location.search);
    defaultTicker = queryParams.get('ticker') || chainTickers.ETH;
    if(!chainDefaults[defaultTicker])
      defaultTicker = chainTickers.ETH;
  } else {
    defaultTicker = chainTickers.ETH;
  }

  let outputNode;

  useEffect(() => {
    if(outputNode) {
      // if(outputNode.scrollTop === (outputNode.scrollHeight - outputNode.offsetHeight)) { // if scrolled to bottom
        setTimeout(() => {
          outputNode.scrollTop = outputNode.scrollHeight;
        }, 0);
      // }
    }
    // setTimeout(() => {
    //   if(outputNode) outputNode.scrollTop = outputNode.scrollHeight;
    // }, 0);
  }, [nodeOutput]);

  const [ balance, setBalance ] = useState('');
  const [ type, setType ] = useState('');
  const [ ticker, setTicker ] = useState('');
  const [ port, setPort ] = useState(0);
  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ host, setHost ] = useState('');
  const [ showPassword, setShowPassword ] = useState(false);
  const [ showKeyPassword, setShowKeyPassword ] = useState(false);
  const [ disableSubmitButton, setDisableSubmitButton ] = useState(false);
  const [ cpu, setCPU ] = useState(1);
  const [ mem, setMem ] = useState(1024);
  const [ totalMem, setTotalMem ] = useState(0);
  const [ network, setNetwork ] = useState('');
  const [ defaultChainsDir, setDefaultChainsDir ] = useState('');
  const [ chainsDir, setChainsDir ] = useState('');
  const [ externalPort, setExternalPort ] = useState(0);
  const [ disableRestart, setDisableRestart ] = useState(false);
  const [ transportProtocol, setTransportProtocol ] = useState(transportProtocols.HTTP);
  const [ role, setRole ] = useState(Role.NODE);
  const [ keyPass, setKeyPass ] = useState('');
  const [ keyPassRepeat, setKeyPassRepeat ] = useState('');
  const [ validatorInfo, setValidatorInfo ] = useState(null);
  const [ selectedChains, setSelectedChains ] = useState(Set());
  const [ chainData, setChainData ] = useState([]);
  const [ showAddRelayChain, setShowAddRelayChain ] = useState(false);
  const [ commandInput, setCommandInput ] = useState('');
  const [ modalCommand, setModalCommand ] = useState('');
  const [ modalOutput, setModalOutput ] = useState('');

  useEffect(() => {
    const getBalance = () => {
      if(selectedChain && selectedChain.role === Role.VALIDATOR) {
        socket.emit(socketEndpoints.GET_NODE_BALANCE, authKey, selectedChain.id, (err, balance) => {
          const { divisor = 1 } = chainDefaults[selectedChain.ticker];
          const preppedBalance = math.divide(math.bignumber(balance), math.bignumber(divisor));
          setBalance(preppedBalance.toString());
        });
      }
    };
    const getBalanceInterval = setInterval(getBalance, 30000);
    getBalance();
    const getValidatorInfo = () => {
      if(selectedChain && selectedChain.role === Role.VALIDATOR) {
        socket.emit(socketEndpoints.GET_VALIDATOR_INFO, authKey, selectedChain.id, (err, info) => {
          setValidatorInfo(info);
        });
      }
    };
    const getValidatorInfoInterval = setInterval(getValidatorInfo, 30000);
    getValidatorInfo();
    return () => {
      clearInterval(getBalanceInterval);
      clearInterval(getValidatorInfoInterval);
    };
  }, [selectedChain]);

  useEffect(() => {
    let closed = false;
    if(selectedChain) {
      setType(selectedChain.remote ? nodeTypes.EXTERNAL : nodeTypes.MANAGED);
      setTicker(selectedChain.ticker);
      setPort(selectedChain.rpcPort);
      setHost(selectedChain.remoteDomain);
      setUsername(selectedChain.rpcUsername);
      setPassword(selectedChain.rpcPassword);
      setCPU(selectedChain.dockerCPUs);
      setMem(selectedChain.dockerMem);
      setNetwork(selectedChain.network);
      setTransportProtocol(selectedChain.protocol);
      setRole(selectedChain.role || Role.NODE);
      setKeyPass('');
      setKeyPassRepeat('');
      setShowKeyPassword(false);
      setSelectedChains(Set());
      setShowAddRelayChain(false);
    } else {
      socket.emit(socketEndpoints.GET_NEXT_CHAIN_PORT, authKey, defaultTicker, (err, port) => {
        if(err) {
          handleError(err);
        } else if(!closed) {
          setTicker(defaultTicker);
          setType(nodeTypes.MANAGED);
          setPort(port);
          setUsername('');
          setPassword('');
          setRole(chainDefaults[defaultTicker].roles[0]);
          setKeyPass('');
          setKeyPassRepeat('');
          setShowKeyPassword(false);
          setHost('');
          setCPU(chainDefaults[defaultTicker].cpus);
          setMem(chainDefaults[defaultTicker].mem);
          setNetwork(networkTypes.MAINNET);
          setSelectedChains(Set());
          setChainData([]);
          setShowAddRelayChain(false);
        }
      });
    }
    return () => {
      closed = true;
    };
  }, [selectedChain]);

  useEffect(() => {
    if(authKey && socket && selectedChain && selectedChain.ticker === 'pokt') {
      socket.emit(socketEndpoints.GET_POCKET_CHAINS, authKey, selectedChain.id, (err, data) => {
        if(err) {
          handleError(err);
        } else {
          setChainData(data.map(item => new PocketChainDataItem(item)));
        }
      });
    }
  }, [socket, authKey, selectedChain]);

  const external = type === nodeTypes.EXTERNAL;

  const onSubmit = async function(e) {
    e.preventDefault();
    setDisableSubmitButton(true);
    const data = {
      type,
      ticker,
      network,
    };
    if(external) {
      const rpcHost = host.replace(/\/+$/, '');
      if(rpcHost.includes(':')) {
        await swal({
          title: 'Oops!',
          text: 'RPC Endpoint should only include the host (e.g. node1.myethnodehost.com/123/abc), not the protocol or port.',
          icon: 'error',
        });
        return setDisableSubmitButton(false);
      }
      Object.assign(data, {
        rpcHost,
        rpcPort: externalPort,
        rpcUsername: username,
        rpcPassword: password,
        protocol: transportProtocol,
      });
      const confirmed = await swal({
        title: 'Confirm endpoint',
        text: `The endpoint you entered is:\n\n${data.protocol}://${host}:${data.rpcPort}\n\nIs this correct?`,
        buttons: [
          'Cancel',
          'Yes, the endpoint is correct',
        ],
        icon: 'warning',
      });
      if(!confirmed) return setDisableSubmitButton(false);
    } else if(role === Role.VALIDATOR && !keyPass) {
      await swal({
        title: 'Oops!',
        text: 'You must enter a key password when creating a validator node.',
        icon: 'error',
      });
      return setDisableSubmitButton(false);
    } else if(role === Role.VALIDATOR && keyPass !== keyPassRepeat) {
      await swal({
        title: 'Oops!',
        text: 'Key password and repeated key password must match.',
        icon: 'error',
      });
      return setDisableSubmitButton(false);
    } else {
      Object.assign(data, {
        rpcPort: port,
        role,
        cpus: cpu,
        mem,
      });
    }
    socket.emit(socketEndpoints.ADD_CHAIN, authKey, data, keyPass, chainsDir, (err, id) => {
      if(err) {
        handleError(err);
        setDisableSubmitButton(false);
      } else {
        history.push(routes.CHAIN + '/' + id);
      }
    });
  };

  useEffect(() => {
    let closed = false;
    socket.emit(socketEndpoints.GET_TOTAL_MEMORY, authKey, (err, res) => {
      if(err) {
        handleError(err);
      } else if(!closed) {
        setTotalMem(Number((res / 1000000).toFixed(0)));
      }
    });
    socket.emit(socketEndpoints.GET_DEFAULT_CHAINS_DIR, authKey, (err, dir) => {
      if(!closed) {
        setDefaultChainsDir(dir);
        setChainsDir(dir);
      }
    });
    return () => {
      closed = true;
    };
  }, []);

  const onTypeChange = e => {
    e.preventDefault();
    const { value } = e.target;
    if(value === nodeTypes.EXTERNAL) {
      setExternalPort(443);
    }
    setType(e.target.value);
  };

  const onExternalPortChange = e => {
    e.preventDefault();
    setExternalPort(parseInt(e.target.value, 10));
  };

  const onUsernameChange = e => {
    e.preventDefault();
    setUsername(e.target.value.trim());
  };

  const onPasswordChange = e => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const onKeyPasswordChange = (e, repeat) => {
    e.preventDefault();
    if(repeat) {
      setKeyPassRepeat(e.target.value);
    } else {
      setKeyPass(e.target.value);
    }
  };

  const onGeneratePassword = async (e, generateKeyPassword = false) => {
    e.preventDefault();
    try {
      const newPassword = await generatePassword(socket, authKey);
      if(generateKeyPassword) {
        setKeyPass(newPassword);
        setKeyPassRepeat(newPassword);
      } else {
        setPassword(newPassword);
      }
    } catch(err) {
      handleError(err);
    }
  };

  const onShowHidePassword = (e, showHideKeyPassword = false) => {
    e.preventDefault();
    if(showHideKeyPassword) {
      setShowKeyPassword(!showKeyPassword);
    } else {
      setShowPassword(!showPassword);
    }
  };

  const onChainChange = e => {
    e.preventDefault();
    const ticker = e.target.value;
    setTicker(ticker);
    setCPU(chainDefaults[ticker].cpus);
    setMem(chainDefaults[ticker].mem);
    setNetwork(networkTypes.MAINNET);
    setChainsDir(defaultChainsDir);
    setRole(chainDefaults[ticker].roles[0]);
    setKeyPass('');
    setKeyPassRepeat('');
    setShowKeyPassword(false);
    const newType = ticker !== chainTickers.ETH ? nodeTypes.MANAGED : type;
    if(newType !== type) setType(newType);
    if(newType === nodeTypes.MANAGED) {
      setUsername(generateUsername(ticker));
      socket.emit(socketEndpoints.GET_NEXT_CHAIN_PORT, authKey, ticker, (err, port) => {
        if(err) {
          handleError(err);
        } else {
          setPort(port);
        }
      });
    }
  };

  const onCPUChange = e => {
    e.preventDefault();
    setCPU(Number(e.target.value));
  };

  const onMemChange = e => {
    e.preventDefault();
    setMem(Number(e.target.value));
  };

  const onHostChange = e => {
    e.preventDefault();
    const protocolPatt = /^(https?):\/\//i;
    const trimmed = e.target.value.trim();
    if(protocolPatt.test(trimmed)) {
      const matches = trimmed.match(protocolPatt);
      setTransportProtocol(matches[1]);
    }
    setHost(trimmed.replace(protocolPatt, ''));
  };

  const onRoleChange = e => {
    e.preventDefault();
    setRole(e.target.value);
  }

  const onTransportProtocolChange = e => {
    e.preventDefault();
    setTransportProtocol(e.target.value);
  };

  const onNetworkChange = e => {
    e.preventDefault();
    setNetwork(e.target.value);
  };

  const nodeOutputArr = selectedChain && nodeOutput.has(selectedChain.id) ? nodeOutput.get(selectedChain.id) : [];
  const consoleOutput = nodeOutputArr.join('\n');

  const onLogsClick = e => {
    e.preventDefault();
    socket.emit(socketEndpoints.GET_LOG_KEY, authKey, (err, key) => {
      if(err) {
        handleError(err);
      } else {
        window.location = window.location.origin + '/log/' + key + '/' + selectedChain.id;
      }
    });
  };

  const onRestartClick = async function(e) {
    e.preventDefault();
    const confirmed = await swal({
      icon: 'warning',
      title: 'Confirm',
      text: `Are you sure that you want to restart ${selectedChain.id.toUpperCase()}?`,
      buttons: [
        'Cancel',
        'OK'
      ]
    });
    if(confirmed) {
      setDisableRestart(true);
      socket.emit(socketEndpoints.RESTART_NODE, authKey, selectedChain.id, false, (err, success) => {
        setDisableRestart(false);
        if(err) {
          handleError(err);
        } else {
          if(success) {
            swal({
              title: 'Success!',
              text: 'Node successfully restarted.',
              icon: 'success',
            });
          } else {
            swal({
              title: 'Oops',
              text: 'There was a problem restarting the node.',
              icon: 'warning',
            });
          }
        }
      });
    }
  };

  const onStopClick = async function(e) {
    e.preventDefault();
    const confirmed = await swal({
      icon: 'warning',
      title: 'Confirm',
      text: `Are you sure that you want to stop ${selectedChain.id.toUpperCase()}?`,
      buttons: [
        'Cancel',
        'OK'
      ]
    });
    if(confirmed) {
      setDisableRestart(true);
      socket.emit(socketEndpoints.STOP_NODE, authKey, selectedChain.id, err => {
        setDisableRestart(false);
        if(err) {
          handleError(err);
        } else {
          swal({
            title: 'Success!',
            text: 'Node successfully stopped.',
            icon: 'success',
          });
        }
      });
    }
  };

  const onSimulateRelayClick = async e => {
    e.preventDefault();
    try {
      const confirmed = await swal({
        title: 'Are you sure?',
        text: 'Simulate Relay should only be used temporarily and for testing purposes. Once you are finished testing, you can restart the node to bring it back to normal. Are you sure that you want to continue?',
        icon: 'warning',
        buttons: [
          'Cancel',
          'OK'
        ],
      });
      if(!confirmed) return;
      setDisableRestart(true);
      socket.emit(socketEndpoints.RESTART_NODE, authKey, selectedChain.id, true, (err, success) => {
        setDisableRestart(false);
        if(err) {
          handleError(err);
        } else {
          if(success) {
            swal({
              title: 'Success!',
              text: 'Node successfully restarted with Simulate Relay enabled.',
              icon: 'success',
            });
          } else {
            swal({
              title: 'Oops',
              text: 'There was a problem restarting the node.',
              icon: 'warning',
            });
          }
        }
      });
    } catch(err) {
      handleError(err);
    }
  };

  const onPullSnapshotClick = async e => {
    e.preventDefault();
    const confirmed = await swal({
      icon: 'warning',
      title: 'Are you sure?',
      text: `This will wipe out your node's currently-synced data and replace it with a recent snapshot of the chain. This can take a while. Do not restart the node until the process finishes. Do you want to continue?`,
      buttons: [
        'Cancel',
        'OK'
      ],
    });
    if(!confirmed) return;
    setDisableRestart(true);
    socket.emit(socketEndpoints.PULL_SNAPSHOT_DATA, authKey, selectedChain, err => {
      if(err) {
        handleError(err);
        setDisableRestart(false);
      } else {
        swal({
          icon: 'success',
          title: 'Success!',
          text: `Snapshot data successfully pulled for ${selectedChain.id}.`
        }).catch(handleError);
        setDisableRestart(false);
      }
    });
  };

  const onClearDataClick = async e => {
    e.preventDefault();
    const confirmed = await swal({
      icon: 'warning',
      title: 'Clear All Chain Data',
      text: `Are you sure that you want to clear the blockchain data for ${selectedChain.id}? You will have to resync the blockchain from scratch or pull down a snapshot after this. Do you want to continue?`,
      buttons: [
        'Cancel',
        'OK',
      ],
    });
    if(!confirmed) return;
    setDisableRestart(true);
    socket.emit(socketEndpoints.CLEAR_NODE_DATA_DIRECTORY, authKey, selectedChain.id, (err, success) => {
      if(err) {
        handleError(err);
      } else {
        swal({
          title: 'Success!',
          text: `Blockchain data directory for ${selectedChain.id} successfully cleared. You can now restart the node.`,
          icon: 'success',
          button: 'OK'
        });
      }
      setDisableRestart(false);
    });
  };

  if(!type) return (
    <div className={'container-fluid'} />
  );

  const notAllowDelete = selectedChain && selectedChain.network === networkTypes.MAINNET && validatorInfo;

  const onDeleteNodeClick = async e => {
    e.preventDefault();
    try {
      if(notAllowDelete) return;
      let confirmed = await swal({
        icon: 'warning',
        title: 'Permanently Delete Node',
        text: `Are you sure that you want to permanently delete ${selectedChain.id}?`,
        buttons: [
          'Cancel',
          `Yes, permanently delete ${selectedChain.id}`,
        ],
      });
      if(!confirmed) return;
      if(validatorInfo) {
        confirmed = await swal({
          icon: 'warning',
          title: 'Are you sure?',
          text: `Any ${selectedChain.network.toLowerCase()} ${ticker.toUpperCase()} that you have staked on ${selectedChain.id} will be lost. Are you sure that you want to permanently delete the node and all keys and data related to it?`,
          buttons: [
            'Cancel',
            `Yes, permanently delete ${selectedChain.id}`,
          ],
        });
        if(!confirmed) return;
      }
      setDisableRestart(true);
      await timeout(1000);
      const usedIn = await new Promise((resolve, reject) => {
        socket.emit(socketEndpoints.CHAIN_USED_EXCLUSIVELY_IN_NODES, authKey, selectedChain.id, (err, usedExclusivelyArr) => {
          if(err) {
            reject(err);
          } else {
            resolve(usedExclusivelyArr);
          }
        });
      });
      if(usedIn.length > 0) {
        setDisableRestart(false);
        return swal({
          icon: 'error',
          title: 'Oops!',
          text: `This node is used by the following nodes: ${usedIn.join(', ')}. You must remove it from the node's relay chains before you can delete it.`,
          button: 'OK'
        });
      }
      await new Promise((resolve, reject) => {
        socket.emit(socketEndpoints.DELETE_NODE, authKey, selectedChain.id, err => {
          if(err) {
            reject(err);
          } else {
            resolve();
          }
        });
      });
      window.location = '/';
    } catch(err) {
      setDisableRestart(false);
      handleError(err);
    }
  };

  const openGetMasterPasswordModal = async function() {
    return await swal({
      text: 'Enter master password',
      content: {
        element: 'input',
        attributes: {
          type: 'password',
        }
      },
      buttons: [
        'Cancel',
        'Unlock',
      ],
    });
  };

  const onViewPasswordClick = async e => {
    e.preventDefault();
    const masterPassword = await openGetMasterPasswordModal();
    if(masterPassword && isString(masterPassword)) {
      socket.emit(socketEndpoints.GET_KEY_PASSWORD, authKey, masterPassword, selectedChain.id, (err, password) => {
        if(err) {
          handleError(err);
        } else {
          swal({
            icon: 'warning',
            title: `${selectedChain.id} key password`,
            text: password,
            button: 'Close',
          }).catch(handleError);
        }
      });
    }
  };

  const onViewKeyClick = async e => {
    e.preventDefault();
    const masterPassword = await openGetMasterPasswordModal();
    if(masterPassword && isString(masterPassword)) {
      socket.emit(socketEndpoints.GET_NODE_KEY, authKey, masterPassword, selectedChain.id, (err, key) => {
        if(err) {
          handleError(err);
        } else {
          swal({
            icon: 'warning',
            title: `${selectedChain.id} private key`,
            text: key,
            button: 'Close',
          }).catch(handleError);
        }
      });
    }
  };

  const hideUsernamePassword = ![chainTickers.BTC, chainTickers.BCH, chainTickers.LTC, chainTickers.DASH, chainTickers.LBC].includes(ticker);

  let showSnapshotButton;
  if(selectedChain) {
    showSnapshotButton = selectedChain.ticker === chainTickers.FUSE
      || selectedChain.ticker === chainTickers.POKT
      || (selectedChain.ticker === chainTickers.ONE && selectedChain.network === 'MAINNET')
      || (selectedChain.ticker === chainTickers.MATIC)
      // || (selectedChain.ticker === chainTicker.OEC && selectedChain.network === 'MAINNET')
      || (selectedChain.ticker === chainTickers.IOTEX);
  } else {
    showSnapshotButton = false;
  }

  const showSimulateRelayButton = selectedChain && selectedChain.ticker === chainTickers.POKT && validatorInfo;

  const onStakeValidatorClick = async e => {
    e.preventDefault();
    const chainInfo = chainDefaults[ticker];
    const minStake = math.bignumber(chainInfo.minStake);
    const divisor = math.bignumber(chainInfo.divisor);
    const displayMinStake = math.divide(minStake, divisor);
    const balanceNum = math.bignumber(balance || '0');
    if(!math.larger(balanceNum, displayMinStake)) {
      return await swal({
        title: 'Insufficient Balance',
        text: `You must have more than ${displayMinStake.toString()} ${ticker.toUpperCase()} in order to stake.`,
        icon: 'warning',
        button: 'OK',
      });
    }
    const amountStr = await swal({
      title: 'Stake Validator',
      text: `Please enter your stake amount. It must include at least ${displayMinStake.toString()} ${ticker.toUpperCase()}.`,
      content: {
        element: 'input',
        attributes: {
          type: 'number',
          placeholder: `Stake amount (minimum ${displayMinStake.toString()})`
        },
        button: 'OK',
      },
      buttons: [
        'Cancel',
        'OK'
      ],
    });
    if(!amountStr) return;
    await timeout(500);
    const amount = math.bignumber(amountStr);
    if(!math.largerEq(amount, displayMinStake)) {
      return await swal({
        title: 'Insufficient Amount',
        text: `You must stake at least ${displayMinStake.toString()} ${ticker.toUpperCase()}.`,
        icon: 'warning',
        button: 'OK',
      });
    } else if(!math.larger(balanceNum, amount)) {
      return await swal({
        title: 'Insufficient Balance',
        text: `You do not have sufficient balance to stake ${amount.toString()} ${ticker.toUpperCase()}.`,
        icon: 'warning',
        button: 'OK',
      });
    }
    const masterPassword = await openGetMasterPasswordModal();
    if(masterPassword && isString(masterPassword)) {
      const fullAmount = math.multiply(amount, divisor).toNumber();
      const fullAmountStr = fullAmount.toLocaleString('fullwide', {useGrouping: false});
      socket.emit(socketEndpoints.STAKE_VALIDATOR, authKey, selectedChain.id, fullAmountStr, masterPassword, (err, txid) => {
        if(err) {
          handleError(err);
        } else if(txid.length > 0) {
          swal({
            icon: 'success',
            title: 'Stake Successful',
            text: `${amount.toString()} ${ticker.toUpperCase()} successfully staked with txid:\n\n${txid}`,
            button: 'Close',
          }).catch(handleError);
        } else {
          swal({
            icon: 'error',
            title: 'Unsuccessful',
            text: 'Unable to stake. Check the logs for more details.'
          });
        }
      });
    } else {
      await timeout(500);
      await swal({
        icon: 'warning',
        title: 'Invalid master password',
      })
    }
  };

  const onShowAddPocketChain = () => {
    setShowAddRelayChain(true);
  };
  const onDeletePocketChain = async c => {
    const namePatt = /https?:\/\/(.+)/;
    const matches = c.url.match(namePatt);
    const confirmed = await swal({
      icon: 'warning',
      title: `Remove ${matches[1]}`,
      text: `Are you sure that you want to remove this relay chain from ${selectedChain.id}?`,
      buttons: [
        'Cancel',
        'OK',
      ],
    });
    if(!confirmed) return;
    socket.emit(socketEndpoints.REMOVE_RELAY_CHAIN, authKey, selectedChain.id, c.id, (err, newChainData) => {
      if(err) {
        handleError(err);
      } else {
        setChainData(newChainData.map(item => new PocketChainDataItem(item)));
        // showChainsUpdatedPrompt();
      }
    });
  };
  const onAddPocketChain = async (chainId, chain) => {
    setShowAddRelayChain(false);
    const confirmed = await swal({
      icon: 'warning',
      title: `Add Relay Chain`,
      text: `Are you sure that you want to add ${chain.remote ? `${chain.remoteProtocol}://${chain.remoteDomain}.${chain.rpcPort}` : `${chainId} ${chain.ticker.toUpperCase()} ${chain.network}`} to ${selectedChain.id}?`,
      buttons: [
        'Cancel',
        'OK',
      ],
    });
    if(!confirmed) return;
    socket.emit(socketEndpoints.ADD_RELAY_CHAIN, authKey, selectedChain.id, chainId, chain, (err, newChainData) => {
      if(err) {
        handleError(err);
      } else {
        setChainData(newChainData.map(item => new PocketChainDataItem(item)));
        // showChainsUpdatedPrompt();
      }
    });
  };
  const onCancelAddPocketChain = () => {
    setShowAddRelayChain(false);
  };
  const onRestakeClick = async e => {
    e.preventDefault();
    const confirmed = await swal({
      icon: 'warning',
      title: 'Restake Node',
      text: `Are you sure that you want to restake ${selectedChain.id}? The restake transaction will cost .01 POKT.`,
      buttons: [
        'Cancel',
        'OK',
      ],
    });
    if(!confirmed) return;
    setDisableRestart(true);
    socket.emit(socketEndpoints.RESTAKE_VALIDATOR, authKey, selectedChain.id, async (err, tx) => {
      if(err) {
        handleError(err);
      } else {
        await swal({
          title: 'Success!',
          text: `${selectedChain.id} restake transaction successfully submitted with txid:\n\n${tx}`,
          icon: 'success',
          button: 'OK'
        });
      }
      setDisableRestart(false);
    });
  };
  const onUnjailClick = async e => {
    e.preventDefault();
    console.log('Unjail!');
    const confirmed = await swal({
      icon: 'warning',
      title: 'Unjail Node',
      text: `The unjail transaction will cost .01 POKT. Continue?`,
      buttons: [
        'Cancel',
        'OK',
      ],
    });
    if(!confirmed) return;
    setDisableRestart(true);
    socket.emit(socketEndpoints.UNJAIL_VALIDATOR, authKey, selectedChain.id, async (err, tx) => {
      if(err) {
        handleError(err);
      } else {
        await swal({
          title: 'Success!',
          text: `${selectedChain.id} unjail transaction successfully submitted with txid:\n\n${tx}`,
          icon: 'success',
          button: 'OK'
        });
      }
      setDisableRestart(false);
    });
  };

  let commandInputNode;
  const onCommandInputChange = e => {
    e.preventDefault();
    setCommandInput(e.target.value);
  };
  const onCommandSubmit = e => {
    e.preventDefault();
    setCommandInput('');
    setModalCommand('');
    setModalOutput('');
    const preppedInput = commandInput.trim();
    socket.emit(socketEndpoints.POCKET_COMMAND_SUBMIT, authKey, selectedChain.id, preppedInput, (err, res = '') => {
      if(err) {
        handleError(err);
      } else {
        setModalCommand(`pocket ${preppedInput}`);
        setModalOutput(res);
        $('#js-nodeOutputModal').modal('show');
      }
    });
  };

  const styles = {
    commandInputContainer: {
      backgroundColor: '#000',
      color: '#0f0',
      fontSize: 14,
      paddingLeft: 8,
      paddingRight: 8,
      cursor: 'text',
    },
    commandInputInitialContainer: {
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: 14,
      lineHeight: '21px',
      whiteSpace: 'pre'
    },
    commandInput: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      borderStyle: 'none',
      color: '#0f0',
      flexGrow: 1,
      display: 'block',
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: 14,
      lineHeight: '21px',
      outline: 'none',
    },
  };

  return (
    <div className={'container-fluid'}>
      <div className={'row'}>
        <div className={'col'}>
          <div className={'w-100 d-flex flex-row justify-content-space-between'}>
            <h2><Link to={routes.HOME}><i className={'mdi mdi-arrow-left'} /></Link> {readOnly ? `${selectedChain.id} node` : 'Add New Node'}</h2>
            <div style={{flexGrow: 1}} />
            {selectedChain && type === nodeTypes.MANAGED ?
              <div style={{paddingTop: 10}}>
                {showSnapshotButton ? <button type={'button'} className={'btn btn-outline-warning btn-sm mr-2'} onClick={onPullSnapshotClick} disabled={disableRestart}>Pull Snapshot</button> : null}
                <button type={'button'} className={'btn btn-outline-danger btn-sm mr-2'} onClick={onClearDataClick} disabled={disableRestart}>Clear Chain Data</button>
                {notAllowDelete ? null : <button type={'button'} className={'btn btn-outline-danger btn-sm mr-2'} onClick={onDeleteNodeClick} disabled={disableRestart}>Delete Node</button>}
                {showSimulateRelayButton ? <button type={'button'} className={'btn btn-outline-warning btn-sm mr-2'} onClick={onSimulateRelayClick} disabled={disableRestart}>Simulate Relay</button> : null}
                {/*<button type={'button'} className={'btn btn-outline-warning btn-sm mr-2'} onClick={onRestartClick} disabled={disableRestart}>Restart Node</button>*/}
                {/*<button type={'button'} className={'btn btn-outline-danger btn-sm mr-2'} onClick={onStopClick} disabled={disableRestart}>Stop Node</button>*/}
                <button type={'button'} className={'btn btn-outline-info btn-sm'} onClick={onLogsClick}>View Node Logs</button>
              </div>
              :
              null
            }
            {selectedChain && external ?
              <div style={{paddingTop: 10}}>
                <button type={'button'} className={'btn btn-outline-danger btn-sm mr-2'} onClick={onDeleteNodeClick} disabled={disableRestart}>Delete Node</button>
              </div>
              :
              null
            }
          </div>
        </div>
      </div>

      <div className={'row'}>
        <form className={'col-md-6'} autoComplete={'off'} onSubmit={onSubmit}>

          <div className={'row'}>
            <div className={'col-lg-8 col-sm-12 form-group'}>
              <label>Chain:</label>
              <select className={'form-control text-monospace'} value={ticker} disabled={readOnly} onChange={onChainChange} required>
                {Object
                  .keys(chainTickers)
                  .map(key => chainTickers[key])
                  .map(ticker => {
                    const name = getChainName(ticker);
                    return (
                      <option key={ticker} value={ticker}>{name ? `${name} (${ticker.toUpperCase()})` : ticker.toUpperCase()}</option>
                    );
                  })
                }
              </select>
            </div>
          </div>

          <div className={'row'}>
            <div className={'col-lg-8 col-sm-12 form-group'}>
              <label>Node Type:</label>
              <select className={'form-control text-monospace'} value={type} onChange={onTypeChange} disabled={readOnly || ticker !== chainTickers.ETH} required>
                <option value={nodeTypes.MANAGED}>Managed node</option>
                <option value={nodeTypes.EXTERNAL}>External, pre-existing node</option>
              </select>
            </div>
          </div>

          <div className={'row'}>
            <div className={'col-lg-8 col-sm-12 form-group'}>
              <label>Network:</label>
              <select className={'form-control text-monospace'} value={network} onChange={onNetworkChange} disabled={readOnly} required>
                {chainDefaults[ticker] ?
                  chainDefaults[ticker].networks
                    .filter(n => {
                      if(readOnly) {
                        return true;
                      } else {
                        return n !== networkTypes.RINKEBY;
                      }
                    })
                    .map(n => {
                      return (
                        <option key={n} value={n}>{n}</option>
                      );
                    })
                  :
                  null
                }
              </select>
            </div>
          </div>

          {selectedChain && selectedChain.address ?
            <div className={'row'}>
              <div className={'col-lg-8 col-sm-12 form-group'}>
                <label>{'Account Address:'} (Balance: <span className={'text-monospace'}>{balance ? `${balance} ${selectedChain.ticker.toUpperCase()}` : 'unknown'}</span>)</label>
                <input type={'text'} className={'form-control text-monospace'} value={selectedChain.address} disabled={true} />
              </div>
            </div>
            :
            null
          }

          {type === nodeTypes.EXTERNAL ?
            <div className={'row'}>
              <div className={'col-lg-8 col-sm-12 form-group'}>
                <label>RPC Endpoint: <em className={'text-muted'}>(e.g. my-node.com or 10.0.0.133 or mainnet.infura.io/v3/123456)</em></label>
                <input type={'text'} className={'form-control text-monospace'} value={host} onChange={onHostChange} disabled={readOnly} required />
              </div>
            </div>
            :
            null
          }

          {type === nodeTypes.MANAGED ?
            <div className={'row'}>
              <div className={'col-lg-8 col-sm-12 form-group'}>
                <label>Role:</label>
                <select className={'form-control text-monospace'} value={role} onChange={onRoleChange} disabled={readOnly || ticker !== chainTickers.FUSE} required>
                  {chainDefaults[ticker].roles.includes(Role.NODE) ? <option value={Role.NODE}>Full Node</option> : null}
                  {chainDefaults[ticker].roles.includes(Role.VALIDATOR) ? <option value={Role.VALIDATOR}>Validator</option> : null}
                </select>
              </div>
            </div>
            :
            null
          }

          {ticker === 'pokt' ?
            selectedChain ?
              <PocketChainData chainData={chainData} onAddChain={onShowAddPocketChain} onDeleteChain={onDeletePocketChain} />
              :
              <div className={'row'}>
                <div className={'col-lg-8 col-sm-12 form-group'}>
                  <label>Connected Chains: (<span className={'text-monospace'}>{selectedChains.size}</span>)</label>
                  {[...chains]
                    .map(c => [c.ticker, c.network, c.loadBalancerId(), c.id, c.remote, c.remoteDomain])
                    .filter(c => ([chainTickers.ETH, chainTickers.BTC, chainTickers.AVAX, chainTickers.XDAI, chainTickers.FUSE, chainTickers.ONE, chainTickers.MATIC, chainTickers.OEC, chainTickers.IOTEX].includes(c[0]) && c[1] === networkTypes.MAINNET) || (c[0] === chainTickers.ETH && c[1] === networkTypes.GOERLI))
                    .filter(c => selectedChain ? (c[4] ? selectedChains.has(c[3]) : selectedChains.has(c[2])) : true)
                    .reduce((arr, c) => {
                      if(c[4]) {
                        return [...arr, c];
                      } else {
                        return arr.some(a => !a[4] && a[2] === c[2]) ? arr : [...arr, c];
                      }
                    }, [])
                    .sort((a, b) => a[2].localeCompare(b[2]))
                    .map(c => {
                      const external = c[4];
                      return (
                        <div key={c[2]} className="form-check">
                          <input disabled={selectedChain ? true : false} className="form-check-input" type="checkbox" id="flexCheckDefault" checked={external ? selectedChains.has(c[3]) : selectedChains.has(c[2])} onChange={e => {
                            // e.preventDefault();
                            if(external) {
                              if(selectedChains.has(c[3])) {
                                setSelectedChains(selectedChains.delete(c[3]));
                              } else {
                                setSelectedChains(selectedChains.add(c[3]));
                              }
                            } else {
                              if(selectedChains.has(c[2])) {
                                setSelectedChains(selectedChains.delete(c[2]));
                              } else {
                                setSelectedChains(selectedChains.add(c[2]));
                              }
                            }
                          }} />
                          <label className="ml-1 form-check-label text-monospace" htmlFor="flexCheckDefault">{c[0].toUpperCase()} - {external ? c[5] : c[1]}</label>
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            :
            null
          }

          {!selectedChain && role === Role.VALIDATOR ?
            <div>
              <div className={'row'}>
                <div className={'col-lg-8 col-sm-12 form-group'}>
                  <label>Key Password: <a href={'#'} onClick={e => onShowHidePassword(e, true)}><em>({showPassword ? 'Hide password' : 'Show password'})</em></a> {!readOnly ? <a href={'#'} onClick={e => onGeneratePassword(e, true)}><em>(Generate password)</em></a> : null}</label>
                  <input type={showKeyPassword ? 'text' : 'password'} className={'form-control text-monospace'} placeholder={'Enter key password'} onChange={onKeyPasswordChange} value={keyPass} />
                </div>
              </div>
              <div className={'row'}>
                <div className={'col-lg-8 col-sm-12 form-group'}>
                  <input type={showKeyPassword ? 'text' : 'password'} className={'form-control text-monospace'} placeholder={'Repeat key password'} onChange={e => onKeyPasswordChange(e, true)} value={keyPassRepeat} />
                </div>
              </div>
            </div>
            :
            null
          }

          {selectedChain && role === Role.VALIDATOR ?
            <div className={'row'}>
              <div className={'col-lg-8 col-sm-12 form-group'}>
                <label>Key Password:</label>
                <button type={'button'} className={'form-control'} onClick={onViewPasswordClick}>Click to unlock and view key password</button>
              </div>
            </div>
            :
            null
          }

          {selectedChain && role === Role.VALIDATOR ?
            <div className={'row'}>
              <div className={'col-lg-8 col-sm-12 form-group'}>
                <label>Private Key:</label>
                <button type={'button'} className={'form-control'} onClick={onViewKeyClick}>Click to decrypt and view private key</button>
              </div>
            </div>
            :
            null
          }

          {type === nodeTypes.EXTERNAL ?
            <div className={'row'}>
              <div className={'col-lg-8 col-sm-12 form-group'}>
                <label>RPC Transport Protocol:</label>
                <select className={'form-control text-monospace'} value={transportProtocol} onChange={onTransportProtocolChange} disabled={readOnly} required>
                  <option value={transportProtocols.HTTP}>HTTP</option>
                  <option value={transportProtocols.HTTPS}>HTTPS</option>
                </select>
              </div>
            </div>
            :
            null
          }

          {readOnly ?
            <div className={'row'}>
              <div className={'col-lg-8 col-sm-12 form-group'}>
                <label>{external ? 'RPC Port' : 'Local RPC Port'}:</label>
                <input type={'number'} className={'form-control'} value={port} disabled={true} />
              </div>
            </div>
            :
            null
          }

          {selectedChain && !external && readOnly ?
            <div className={'row'}>
              <div className={'col-lg-8 col-sm-12 form-group'}>
                <label>Peer Port:</label>
                <input type={'number'} className={'form-control'} value={selectedChain.peerPort} disabled={true} />
              </div>
            </div>
            :
            null
          }

          {!readOnly && external ?
            <div className={'row'}>
              <div className={'col-lg-8 col-sm-12 form-group'}>
                <label>RPC Port:</label>
                <input type={'number'} className={'form-control'} value={externalPort} onChange={onExternalPortChange} required />
              </div>
            </div>
            :
            null
          }

          {!external ?
            <div className={'row'}>
              <div className={'col-lg-8 col-sm-12 form-group'}>
                <label>vCPUs:</label>
                <input type={'number'} className={'form-control text-monospace'} min={1} value={cpu} onChange={onCPUChange} disabled={readOnly} required />
              </div>
            </div>
            :
            null
          }

          {!external ?
            <div className={'row'}>
              <div className={'col-lg-8 col-sm-12 form-group'}>
                <label>Maximum Memory Allowance: <em
                  className={'text-muted'}>{`(Out of ${totalMem.toLocaleString()} MB total system memory)`}</em></label>
                <input type={'number'} className={'form-control text-monospace'} value={mem} onChange={onMemChange}
                       disabled={readOnly} min={512} step={512} required/>
              </div>
            </div>
            :
            null
          }

          {!external ?
            <div className={'row'}>
              <div className={'col-lg-8 col-sm-12'}>
                {selectedChain ?
                  <div className={'form-group'}>
                    <label>Data Directory:</label>
                    <input type={'text'} className={'form-control text-monospace'} value={selectedChain ? selectedChain.dataDir : ''} disabled={true} />
                  </div>
                  :
                  <ChainDirSelect authKey={authKey} socket={socket} defaultValue={defaultChainsDir} value={chainsDir} onChange={setChainsDir} />
                }
              </div>
            </div>
            :
            null
          }

          {readOnly && !hideUsernamePassword ?
            <div className={'row'}>
              <div className={'col-lg-8 col-sm-12 form-group'}>
                <label>RPC Username:</label>
                <input type={'text'} className={'form-control text-monospace'} value={username} onChange={onUsernameChange} disabled={readOnly} required={!external} />
              </div>
            </div>
            :
            null
          }

          {readOnly && !hideUsernamePassword ?
            <div className={'row'}>
              <div className={'col-lg-8 col-sm-12 form-group'}>
                <label>RPC Password: <span style={{display: external ? 'none' : 'inline'}}><a href={'#'} onClick={onShowHidePassword}><em>({showPassword ? 'Hide password' : 'Show password'})</em></a> {!readOnly ? <a href={'#'} onClick={onGeneratePassword}><em>(Generate password)</em></a> : null}</span></label>
                <input type={showPassword ? 'text' : 'password'} className={'form-control text-monospace'} value={password} onChange={onPasswordChange} disabled={readOnly} autoComplete={'off'} required={!external}/>
              </div>
            </div>
            :
            null
          }

          {!readOnly ?
            <div className={'row'}>
              <div className={'col-lg-8 col-sm-12 form-group'}>
                <button disabled={disableSubmitButton} type={'submit'} className={'btn btn-primary'}>{type === nodeTypes.MANAGED ? `Create ${ticker.toUpperCase()} node` : `Add ${ticker.toUpperCase()} node`}</button>
              </div>
            </div>
            :
            null
          }

        </form>

        {(readOnly && type && !external) ?
          <div className={'col-md-6 mb-2'}>
            <h3>Lifecycle Controls</h3>
            <div className={'card'}>
              <div className={'card-body d-flex flex-row justify-content-start'}>
                {/*<button type={'button'} className={'btn btn-outline-success mr-2'} onClick={e => onRestartClick(e, true)}>Start Node</button>*/}
                <button type={'button'} className={'btn btn-sm btn-outline-warning mr-2'} onClick={onRestartClick} disabled={disableRestart}>Restart Node</button>
                <button type={'button'} className={'btn btn-sm btn-outline-danger mr-2'} onClick={onStopClick} disabled={disableRestart}>Stop Node</button>
              </div>
            </div>
            {selectedChain.role === Role.VALIDATOR ?
              <div>
                <h3>Validator Info</h3>
                <div className={'card'}>
                  {validatorInfo ?
                    <div className={'card-body'}>
                      <div className={'mb-2 d-flex flex-row justify-content-start'}>
                        <button type={'button'} className={'btn btn-sm btn-outline-warning mr-2'} onClick={onRestakeClick}>Restake Node</button>
                        {!validatorInfo.jailed ? <button type={'button'} className={'btn btn-sm btn-outline-warning mr-2'} onClick={onUnjailClick}>Unjail Node</button> : null}
                      </div>
                      <ul>
                        {Object.entries(validatorInfo)
                          .map(([ key, val ]) => {
                            let valStr = '';
                            if(key === 'stakedAmount') {
                              const divisor = math.bignumber(chainDefaults[ticker].divisor);
                              const res = math.divide(math.bignumber(val), divisor);
                              valStr = res.toString() + ' ' + ticker.toUpperCase();
                            } else {
                              try {
                                valStr = isString(val) ? val : val.toString();
                              } catch(err) {
                                // ignore error
                              }
                            }
                            return (
                              <li key={key}><strong style={{display: 'inline-block', minWidth: 140}}>{separateCamelCase(key)}:</strong> {valStr}</li>
                            );
                          })
                        }
                      </ul>
                    </div>
                    :
                    <div className={'card-body d-flex flex-row justify-content-start'}>
                      <button type={'button'} className={'btn btn-sm btn-outline-warning mr-2'}
                              onClick={onStakeValidatorClick}
                              disabled={disableRestart}>Stake Validator
                      </button>
                    </div>
                  }
                </div>
              </div>
              :
              null
            }
            {ticker === chainTickers.POKT ?
              <div>
                <h3>Command Entry <em style={{fontSize: 20, fontWeight: 'normal'}} className={'text-muted'}>(e.g. pocket util print-configs)</em></h3>
                <form className={'d-flex flex-row flex-nowrap justify-content-start text-monospace'} style={styles.commandInputContainer} autoComplete={'off'} onSubmit={onCommandSubmit}>
                  <div style={styles.commandInputInitialContainer} className={'command-input-label'} onClick={() => commandInputNode ? commandInputNode.focus() : null}>{'$ pocket '}</div>
                  <input ref={node => commandInputNode = node} className={'command-input'} style={styles.commandInput} spellCheck={false} value={commandInput} onChange={onCommandInputChange} />
                </form>
              </div>
              :
              null
            }
            {showAddRelayChain ?
              <AddPocketRelayChain chainData={chainData} selectedNode={selectedChain} onAddChain={onAddPocketChain}
                                   onCancel={onCancelAddPocketChain}/>
              :
              <div>
                <h2>Node Output</h2>
                <div ref={node => node ? outputNode = node : null} style={{
                  backgroundColor: '#000',
                  color: '#0f0',
                  paddingLeft: 8,
                  height: 500,
                  whiteSpace: 'pre-wrap',
                  overflowY: 'auto'
                }} className={'text-monospace'}>{consoleOutput}</div>
              </div>
            }
          </div>
          :
          null
        }

      </div>

      <OutputModal command={modalCommand} output={modalOutput} />

    </div>
  );
};
AddNewChain.propTypes = {
  authKey: PropTypes.string,
  history: PropTypes.object,
  selectedChain: PropTypes.instanceOf(NLNode),
  socket: PropTypes.object,
  readOnly: PropTypes.bool,
  nodeOutput: PropTypes.instanceOf(Map),
  chains: PropTypes.arrayOf(PropTypes.instanceOf(NLNode)),
};

export default AddNewChain;
